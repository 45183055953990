(function () {
  'use strict';

  angular
    .module('dashboard.groups')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.groups', {
        url: '/groups',
        templateUrl: 'dashboard/groups/groups.tpl.html',
        controller: 'GroupsCtrl',
        controllerAs: 'groups',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
